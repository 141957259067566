const addresses = {
  97: {
    bnb: "0x0000000000000000000000000000000000000000",
    // bscCoinFlip: "0x5dE68463ce82BaB84065Acdac1A24e0DB494C67B",
    // bscCoinFlip: "0x146Bc6AB8fBcA8fc209eD1F3D4f440b16b917626",
    bscCoinFlip: "0xDC4Dd1975d77bA8143cfAF4C7941ab0e7a5c1C00",
    bscF: "0x7b5D6742f7BC3289FA904Aaf7d91042651BcD702",
  },
  56: {
    bnb: {
      address: "0x0000000000000000000000000000000000000000",
      symbol: "bnb",
      decimals: 18,
      contractName: "Binance Coin",
    },

    bscF: {
      address: "0xe3616062fffcc5c4354d53bc1c8369265afd8654",
      symbol: "bscb",
      decimals: 9,
      contractName: "BSCBet Token",
      premium: "no",
    },
    bscb: {
      address: "0xe3616062fffcc5c4354d53bc1c8369265afd8654",
      decimals: 9,
      contractName: "BSCBet Token",
      website: "bscbet.io",
      tg: "bscbetportal",
      premium: "yes",
      colour1: "red",
      colour2: "black",
    },
    stake: {
      address: "0x2d685b00e649510c75918506f4a3618a14cdb48f",
      // symbol: "stake",
      decimals: 9,
      contractName: "Stake N' Chill",
    },
    ftale: {
      address: "0xda15d7ac0b6c1c5f5f3798d0077a3d18e3f55a90",
      // symbol: "ftale",
      decimals: 9,
      contractName: "Fairytale",
    },
    dape: {
      address: "0xb7525c764829fe7bc07470d2b15a94fe5380a967",
      symbol: "dape",
      decimals: 9,
      contractName: "DEFIAPES",
    },
    flokizilla: {
      address: "0x9686c4ab501a678a95db00fd70fd763c641346c5",
      symbol: "flokizilla",
      decimals: 9,
      contractName: "FlokiZilla",
    },
    apu: {
      address: "0xcf627bf116dbe7f77ae97cc948b5ba05d4c3ba7c",
      symbol: "apu",
      decimals: 9,
      contractName: "Apu",
    },
    qusdt: {
      address: "0x03474a2d7ff5656dab95071fef8cb72460fe2f04",
      // symbol: "qusdt",
      decimals: 9,
      contractName: "Quadrillionaire USDT",
    },
    mbx: {
      address: "0xe68d3a367fee641e44d159ea720f40dd86f00499",
      symbol: "mbx",
      decimals: 18,
      contractName: "MOONBASEX",
    },
    paypal: {
      address: "0x65c12d9dc1de45facd71662208754a1d24d122c4",
      symbol: "paypal",
      decimals: 9,
      contractName: "PayPal Inu",
      website: "t.me/PaypalInuBNB",
      tg: "PaypalInuBNB",
      premium: "yes",
      colour1: "#3f84d3",
      colour2: "white",
    },
    xbusd: {
      address: "0xe54de6cf9872fea4b99a0e9243df0089829b90f8",
      symbol: "xbusd",
      decimals: 9,
      contractName: "xBUSD",
    },
    pepc: {
      address: "0xa7ce69fd72210abd09ebba3b0bdb4324b1328861",
      symbol: "pepc",
      decimals: 9,
      contractName: "Pepe Classic",
    },
    mtinu: {
      address: "0xff41cdb3f053be89cd92429cdef287198d027dbd",
      // symbol: "mtinu",
      decimals: 9,
      contractName: "minitigeinu",
    },
    bscCoinFlip: {
      address: "0x4ee4C1243C4b0B30BE3Fc833eE2a0E6070200673",
      contractName: "BSCBet",
    },
  },
  421613: {
    bnb: "0x0000000000000000000000000000000000000000",
    bscCoinFlip: "0x27dADf0Ff4A3F8AD27C45f184e175486Ea9DbF88",
    bscF: "0x4ee4C1243C4b0B30BE3Fc833eE2a0E6070200673",
    usdc: "0x42437026bD32BC07914dc1FB35B8009805277915",
  },
  42161: {
    eth: "0x0000000000000000000000000000000000000000",
    bscCoinFlip: "0x27dADf0Ff4A3F8AD27C45f184e175486Ea9DbF88",
    bscF: "0x4ee4C1243C4b0B30BE3Fc833eE2a0E6070200673",
    usdc: "0x42437026bD32BC07914dc1FB35B8009805277915",
  },
};

export default addresses;
