import styled from "styled-components";
import { DoubleOrNothing } from "./DoubleOrNothing";

export const FlipContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 2px;
`;

export const GameButton = styled.button`
  display: flex
  height: 3rem;
  width: 100px;
  background-color: ${(props) => (props.isActive ? "#606060" : "black")};

  border-radius: 10px;
  color: white;
  cursor: ${(props) => (!props.isDisabled ? "pointer" : "not-allowed")};
  font-size: 12px;
  text-align: center;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin: 5px 5px;
  padding: 12px 10px;
  opacity: ${(props) => (!props.isDisabled ? 1 : 0.5)};
  font-weight: 200;

  ${(props) => props.hidden && "hidden"} :focus {
    border: none;
    outline: none;
  }

  ${(props) => !props.isDisabled && ":hover"} {

    box-shadow: 0px 0px 5px 1px red;
    opacity: 0.7;
    transtion: ease 0.5s all;
  }
`;

export const ColorButton = styled.button`
  display: flex
  height: 3rem;
  width: ${(props) => (!props.wide ? "210px" : "200px")};
  background-color: ${(props) => (props.isActive ? "#F1B913" : "black")};

  border-radius: 8px;
  color: white;
  cursor: ${(props) => (!props.isDisabled ? "pointer" : "not-allowed")};
  font-size: 13px;
  text-align: center;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin: ${(props) => (!props.spaced ? "5px 5px" : "5px 10px")};
  padding: 14px 12px;
  opacity: ${(props) => (!props.isDisabled ? 1 : 0.5)};
  font-weight: bold;

  ${(props) => props.hidden && "hidden"} :focus {
    border: none;
    outline: none;
  }

  ${(props) => !props.isDisabled && ":hover"} {
    background-color: ${(props) => (props.isActive ? "#F1B213" : "#2F3236")};
    box-shadow: 0px 0px 5px 1px red;
    opacity: 0.7;
    transtion: ease 0.5s all;
  }
`;

export const DoubleOrNothingBtn = styled.button`
  height: 50px;
  width: 180px;
  color: white;
  background-color: #3590f3;
  border-radius: 15px;
  z-index: 1;

  cursor: ${(props) => (!props.isDisabled ? "pointer" : "not-allowed")};
  opacity: ${(props) => (!props.isDisabled ? 1 : 0.5)};
  font-weight: 200;

  ${(props) => !props.isDisabled && ":hover"} {
    background-color: #3590f3;
    color: white;
    box-shadow: 0 5px 30px rgba(53, 144, 243, 0.8);
  }
`;
