import { useState, useEffect } from "react";

import styled, { keyframes, css } from "styled-components";

import {
  DropDownContainer,
  DropDownHeader,
  DropDownListContainer,
  DropDownList,
  ListItem,
  Caret,
  OverCaret,
} from "./DropDownStyles";

import { Div } from "atomize";

export const DropDown = ({
  options,
  onOptionClicked,
  selectedOption,
  isOpen,
  toggling,
}) => {
  return (
    <Div>
      <DropDownContainer>
        <DropDownHeader onClick={toggling}>
          {selectedOption.toUpperCase()}
          <Caret isOpen={isOpen}>
            <OverCaret isOpen={isOpen} />
          </Caret>
        </DropDownHeader>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList>
              {options.map((option) => (
                <ListItem onClick={onOptionClicked(option)} key={option}>
                  {option}
                </ListItem>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </Div>
  );
};
