import { useEffect, useState } from "react";
import { addresses } from "../../contracts/src";
import Web3 from "web3";
import MyAbi from "../Game/abi/bsccoinflip.json";
import { Div, Text } from "atomize";
import { GameTable } from "./RecentTxStyles";

export function CurrentGames({ contractAddress, gameSymbol, chainId }) {
  const [transactions, setTransactions] = useState([]);
  const abi = MyAbi;
  const ca = "0x4ee4C1243C4b0B30BE3Fc833eE2a0E6070200673";
  const [gameStates, setGameStates] = useState({});

  async function fetchTransactions() {
    // initialize web3 with the BSC provider
    const web3 = new Web3(
      "https://weathered-small-paper.bsc.discover.quiknode.pro/b6d6d14c47c066a9c44362e00141c74db5bfe15a/"
    );

    // get the contract instance using web3
    const contract = new web3.eth.Contract(
      abi, // replace with your contract ABI
      "0x4ee4C1243C4b0B30BE3Fc833eE2a0E6070200673" // replace with your contract address
    );

    // get the latest block number
    const latestBlockNumber = await web3.eth.getBlockNumber();

    // fetch the latest 10 transactions from the contract
    const blockNumberStart = Math.max(0, latestBlockNumber - 1000);
    const blockNumberEnd = latestBlockNumber;
    const events = await contract.getPastEvents("allEvents", {
      fromBlock: blockNumberStart,
      toBlock: blockNumberEnd,
    });

    // map the events to transaction objects
    const transactionObjects = events.map((event) => ({
      transactionHash: event.transactionHash,
      better: event.returnValues.better,
      from: event.sender,
      wager: event.returnValues.wager,
      token: event.returnValues.token,
      predictedOutcome: event.returnValues.predictedOutcome,
      winner: event.returnValues.winner,
      id: event.returnValues.id,
      gameStarted: event.event === "GameStarted",
      gameFinished: event.event === "GameFinished",
    }));

    const newGameStates = {};
    transactionObjects.forEach((transaction) => {
      if (transaction.gameStarted) {
        newGameStates[transaction.id] = { started: true, finished: false };
      }
      if (transaction.winner !== undefined && transaction.winner !== null) {
        newGameStates[transaction.id] = {
          ...newGameStates[transaction.id],
          finished: true,
        };
      }
    });
    setGameStates((prevGameStates) => ({
      ...prevGameStates,
      ...newGameStates,
    }));

    // update the state with the transaction objects

    setTimeout(() => {
      setTransactions(transactionObjects);
    }, 500);
  }

  const gameTokens = Object.values(addresses[56]).map((token) =>
    token.address.toLowerCase()
  );
  console.log(gameTokens);

  useEffect(() => {
    fetchTransactions();
  }, [transactions]);

  return (
    <Div d="flex" flexDir={{ xl: "row", lg: "row", xs: "column" }}>
      <Div m={{ b: "0.5rem" }}>{/* <RecentGameOutcomes /> */}</Div>
      <Div m={{ x: "1rem" }}>
        <Div bg="#212124" rounded="10px">
          <Div d="flex">
            <Div
              textColor="white"
              p={{ x: "1rem", t: "1rem" }}
              w="50%"
              textSize="12px"
              d="flex">
              <Div
                bg={
                  addresses[chainId][gameSymbol].premium === "yes"
                    ? addresses[chainId][gameSymbol].colour1
                    : "Red"
                }
                p="0.5rem"
                rounded="10px"
                textColor={
                  addresses[chainId][gameSymbol].premium === "yes" &&
                  addresses[chainId][gameSymbol].colour1 === "white"
                    ? "black"
                    : "white"
                }>
                {
                  transactions.filter(
                    (transaction) =>
                      parseInt(transaction.predictedOutcome) === 0 &&
                      gameStates[transaction.id]?.started === true &&
                      gameStates[transaction.id]?.finished !== true
                  ).length
                }{" "}
                Bets Total
              </Div>
            </Div>
            <Div
              textColor="lime"
              p={{ x: "1rem", t: "1rem" }}
              w="50%"
              textSize="12px"
              textAlign="right"
              d="flex"
              align="center"
              justify="right">
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
                fill="gold"
              >
                <path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2l0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5V176c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336V300.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4V304v5.7V336zm32 0V304 278.1c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5V272c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5V432c0 44.2-86 80-192 80S0 476.2 0 432V396.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z" />
              </svg>
              <Text m={{ l: "4px" }} textSize="12px">
                1.70
              </Text> */}
            </Div>
          </Div>
          <hr></hr>
          <GameTable>
            <thead>
              <th style={{ paddingLeft: "1rem" }}>User</th>
              <th>Token</th>
              <th style={{ textAlign: "right", paddingRight: "1rem" }}>
                Amount
              </th>
            </thead>
            <tbody>
              {transactions
                .filter(
                  (transaction) =>
                    parseInt(transaction.predictedOutcome) === 0 &&
                    gameStates[transaction.id]?.started === true &&
                    gameStates[transaction.id]?.finished !== true
                ) // Filter by transactions where gameStarted is true
                .filter((transaction) => transaction.wager > 0)
                .sort((a, b) => b.id - a.id)
                .slice(0, 10)
                .map((transaction) => (
                  <tr key={transaction.transactionHash}>
                    <td style={{ paddingLeft: "1rem" }}>
                      {transaction.better.slice(0, 6)}{" "}
                    </td>
                    <td>
                      {gameTokens.includes(transaction.token.toLowerCase()) &&
                        Object.values(addresses[56])
                          .find(
                            (token) =>
                              token.address === transaction.token.toLowerCase()
                          )
                          .symbol.toUpperCase()}
                    </td>
                    <td style={{ textAlign: "right", paddingRight: "1rem" }}>
                      {transaction.token ===
                      "0x0000000000000000000000000000000000000000"
                        ? transaction.wager *
                          10 **
                            -(
                              gameTokens.includes(
                                transaction.token.toLowerCase()
                              ) &&
                              Object.values(addresses[56]).find(
                                (token) =>
                                  token.address ===
                                  transaction.token.toLowerCase()
                              ).decimals
                            )
                        : parseInt(
                            transaction.wager *
                              10 **
                                -(
                                  gameTokens.includes(
                                    transaction.token.toLowerCase()
                                  ) &&
                                  Object.values(addresses[56]).find(
                                    (token) =>
                                      token.address ===
                                      transaction.token.toLowerCase()
                                  ).decimals
                                )
                          )}
                      {}
                    </td>
                  </tr>
                ))}
            </tbody>
          </GameTable>
        </Div>
      </Div>
      <Div m={{ x: "1rem" }}>
        <Div bg="#212124" rounded="10px">
          <Div d="flex">
            <Div
              d="flex"
              textColor="white"
              p={{ x: "1rem", t: "1rem" }}
              w="50%"
              textSize="12px"
              align="center">
              <Div
                bg={
                  addresses[chainId][gameSymbol].premium === "yes"
                    ? addresses[chainId][gameSymbol].colour2
                    : "Black"
                }
                p="0.5rem"
                rounded="10px"
                textColor={
                  addresses[chainId][gameSymbol].premium === "yes" &&
                  addresses[chainId][gameSymbol].colour2 === "white"
                    ? "black"
                    : "white"
                }>
                {
                  transactions.filter(
                    (transaction) =>
                      parseInt(transaction.predictedOutcome) === 1 &&
                      gameStates[transaction.id]?.started === true &&
                      gameStates[transaction.id]?.finished !== true
                  ).length
                }{" "}
                Bets Total
              </Div>
            </Div>
            <Div
              textColor="lime"
              p={{ x: "1rem", t: "1rem" }}
              w="50%"
              textSize="12px"
              textAlign="right"
              d="flex"
              align="center"
              justify="right">
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
                fill="gold"
              >
                <path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2l0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5V176c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336V300.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4V304v5.7V336zm32 0V304 278.1c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5V272c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5V432c0 44.2-86 80-192 80S0 476.2 0 432V396.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z" />
              </svg>
              <Text m={{ l: "4px" }} textSize="12px">
                0.64
              </Text> */}
            </Div>
          </Div>
          <hr></hr>
          <GameTable>
            <thead>
              <th style={{ paddingLeft: "1rem" }}>User</th>
              <th>Token</th>
              <th style={{ textAlign: "right", paddingRight: "1rem" }}>
                Amount
              </th>
            </thead>
            <tbody>
              {transactions
                .filter(
                  (transaction) =>
                    parseInt(transaction.predictedOutcome) === 1 &&
                    gameStates[transaction.id]?.started === true &&
                    gameStates[transaction.id]?.finished !== true
                )
                .sort((a, b) => b.id - a.id)
                .slice(0, 10)
                .map((transaction) => (
                  <tr key={transaction.transactionHash}>
                    <td style={{ paddingLeft: "1rem" }}>
                      {transaction.better.slice(0, 6)}{" "}
                    </td>
                    <td>
                      {gameTokens.includes(transaction.token.toLowerCase()) &&
                        Object.values(addresses[56])
                          .find(
                            (token) =>
                              token.address === transaction.token.toLowerCase()
                          )
                          .symbol.toUpperCase()}
                    </td>
                    <td style={{ textAlign: "right", paddingRight: "1rem" }}>
                      {transaction.token ===
                      "0x0000000000000000000000000000000000000000"
                        ? transaction.wager *
                          10 **
                            -(
                              gameTokens.includes(
                                transaction.token.toLowerCase()
                              ) &&
                              Object.values(addresses[56]).find(
                                (token) =>
                                  token.address ===
                                  transaction.token.toLowerCase()
                              ).decimals
                            )
                        : parseInt(
                            transaction.wager *
                              10 **
                                -(
                                  gameTokens.includes(
                                    transaction.token.toLowerCase()
                                  ) &&
                                  Object.values(addresses[56]).find(
                                    (token) =>
                                      token.address ===
                                      transaction.token.toLowerCase()
                                  ).decimals
                                )
                          )}
                      {}
                    </td>
                  </tr>
                ))}
            </tbody>
          </GameTable>
        </Div>
      </Div>
    </Div>
  );
}
