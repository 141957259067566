import styled from "styled-components";

export const Header = styled.header`
  position: relative;
  z-index: 2;
  min-height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  color: black;
`;

export const TxTable = styled.table`
  width: ${(props) => (!props.wide ? "55rem" : "20rem")};
  background-color: none;
  font-size: 12px;
  padding: 1rem;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
  color: blue;
  border-radius: 20px;

  @media (max-width: 1000px) {
    width: 30rem;
  
  @media (max-width: 500px) {
    width: 22rem;

  @media (max-width: 400px) {
    width: 20rem;
`;

export const GameTable = styled.table`
  width: ${(props) => (!props.wide ? "27rem" : "20rem")};
  background-color: none;
  font-size: 12px;
  padding: 1rem;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
  color: blue;
  border-radius: 20px;

  @media (max-width: 1000px) {
    width: 30rem;
  
  @media (max-width: 500px) {
    width: 22rem;

  @media (max-width: 400px) {
    width: 20rem;
`;

export const HeaderButton = styled.button`
  height: 40px;
  width: ${(props) => (!props.wide ? "120px" : "120px")};
  background-color: black;
  border: none;
  border-radius: 10px;
  color: white;
  cursor: ${(props) => (!props.isDisabled ? "pointer" : "not-allowed")};
  font-size: 13px;
  text-align: center;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin: 0px 5px;
  padding: 12px 10px;
  font-weight: 300;
  opacity: ${(props) => (!props.isDisabled ? 1 : 0.5)};

  ${(props) => props.hidden && "hidden"} :focus {
    border: none;
    outline: none;
  }

  &:hover {
    opacity: 0.6;
    transition: ease 0.25s all;
  }
`;
