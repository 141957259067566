import React, { useEffect, useState } from "react";
import { Div, Text, Button, Image } from "atomize";
import bscbet from "../../img/bscbet.png";
import dice from "../../img/dice.png";

// import { RecentTransactionsTable } from "../RecentTxs/RecentTxs";

export const StartPage = () => {
  return (
    <div>
      <Div
        textColor="black"
        minH="100vh"
        w="100vw"
        d="flex"
        flexDir="column"
        align="center"
        p={{ x: "1rem" }}>
        {/* <Text
          textColor="black"
          tag="p"
          textSize="100px"
          textAlign="center"
          
        >
          BSCBet
        </Text> */}
        <Div m={{ t: "5rem" }}>
          {/* <Div
            d="flex"
            w="60rem"
            h="10rem"
            bg="#161618"
            rounded="10px"
            align="center"
            justify="center"
          >
            <Text textColor="gray900" textWeight="bold">
              ADS COMING SOON
            </Text>
          </Div> */}
        </Div>

        <Div
          d="flex"
          flexDir={{ xl: "row", xs: "column" }}
          bg="linear-gradient(200deg, #050505, #212124)"
          w="90vw"
          minH="12rem"
          rounded="10px"
          justify="center"
          align="center"
          m={{ t: "1rem" }}>
          <Div
            d="flex"
            flexDir="column"
            align="center"
            textAlign="center"
            justify="center"
            m={{ t: "1.5rem" }}>
            <Div>
              <Text
                textColor="white"
                textSize={{ xl: "34px", xs: "30px" }}
                textWeight="bold"
                m={{ b: "1rem " }}>
                Welcome to BSCBet!
              </Text>
            </Div>
            <Text
              textColor="gray900"
              tag="p"
              textSize="16px"
              textAlign="center">
              No signup, instant withdrawls and on-chain!
            </Text>
            <Div m={{ t: "2rem" }}>
              <Div d="flex">
                <Div m={{ r: "0.5rem" }}>
                  <Button
                    tag="a"
                    href="/Roulette"
                    w="6rem"
                    p="0"
                    textColor="black"
                    bg="white"
                    textSize="10px"
                    h="2.25rem">
                    PLAY NOW
                  </Button>
                </Div>
                <Div d="flex">
                  <Div
                    tag="a"
                    href="https://t.me/BSCBetPortal"
                    cursor="pointer"
                    d="flex"
                    align="center"
                    justify="center"
                    bg="#212124"
                    p="0.5rem"
                    rounded="5px"
                    m={{ x: "0.25rem" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="white"
                      viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z" />
                    </svg>
                  </Div>
                  <Div
                    tag="a"
                    align="center"
                    justify="center"
                    href="https://www.dextools.io/app/en/bnb/pair-explorer/0xf1d285bc6239a13f4181654fd31ab5939dffad2a"
                    cursor="pointer"
                    d="flex"
                    bg="#212124"
                    p="0.5rem"
                    rounded="5px"
                    m={{ x: "0.25rem" }}>
                    <Image
                      src="https://seeklogo.com/images/D/dextools-logo-4EDDD2DF98-seeklogo.com.png"
                      w="20px"
                      h="20px"
                    />
                  </Div>
                </Div>
              </Div>
            </Div>
            <Text
              p={{ t: "1rem", b: "1.5rem" }}
              textSize="12px"
              textSpacing="20px"
              textWeight="regular"
              textColor="gray900">
              Connect your wallet to play.
            </Text>
          </Div>
        </Div>
        <Div
          d="flex"
          flexDir={{ xl: "row", xs: "column" }}
          w="100vw"
          m={{ y: "2rem" }}
          justify="center"
          align="center">
          <Div d="block" m={{ x: "1rem" }}>
            <Div
              d="block"
              bgImg={dice}
              bgSize="15rem"
              bgPos="-10rem"
              w={{ xl: "39.5rem", xs: "90vw" }}
              m={{ b: { xl: "0rem", xs: "2rem" } }}
              h="12rem"
              bg="#161618"
              rounded="10px">
              <div />
              <Div h="4rem" d="flex" align="center" opacity="2">
                <Text
                  textColor="white"
                  textWeight="bold"
                  textSize="30px"
                  m={{ x: "2rem", t: "2rem", b: "1rem" }}>
                  ROULETTE
                </Text>
              </Div>
              <Text textColor="white" textSize="16px" m={{ l: "2rem" }}>
                Fully on chain
              </Text>
              <Div m="2rem">
                <Button
                  tag="a"
                  href="/Roulette"
                  w="6rem"
                  p="0"
                  textColor="black"
                  bg="white"
                  textSize="10px"
                  h="2rem">
                  PLAY NOW
                </Button>
              </Div>
            </Div>
          </Div>
          <Div d="block" m={{ x: "1rem" }}>
            <Div
              d="block"
              bgImg={bscbet}
              bgSize="5rem"
              bgPos="center"
              w={{ xl: "39.5rem", xs: "90vw" }}
              h="12rem"
              bg="#161618"
              rounded="10px">
              <Div h="4rem" d="flex" align="center">
                <Text
                  textColor="white"
                  textWeight="bold"
                  textSize="30px"
                  m={{ x: "2rem", t: "2rem", b: "1rem" }}>
                  LISTINGS
                </Text>
              </Div>
              <Text textColor="white" textSize="16px" m={{ l: "2rem" }}>
                Get listed on BSCBet
              </Text>
              <Div>
                <Button
                  tag="a"
                  href="https://forms.gle/wjcmoqtZr8kDHyr18"
                  w="6rem"
                  textColor="black"
                  bg="white"
                  textSize="10px"
                  h="2rem"
                  p="0"
                  m="2rem">
                  GET LISTED
                </Button>
              </Div>
            </Div>
          </Div>
        </Div>

        <Div
          d="flex"
          w="100vw"
          justify="center"
          align="center"
          flexDir={{ xl: "row", xs: "column" }}
          m={{ b: "2rem" }}>
          <Div
            w={{ xl: "25.5rem", xs: "90vw" }}
            h="13rem"
            rounded="20px"
            bg="linear-gradient(120deg, blue, #ff0050)"
            d="block"
            m={{
              x: { xl: "1rem", xs: "none" },
              y: { xl: "0rem", xs: "1rem" },
            }}>
            <Div m="2rem">
              <Text textColor="white" textSize="34px" textWeight="bold">
                SPIN NOW
              </Text>
              <Text textColor="white" textSize="18px" m={{ t: "0.5rem" }}>
                WIN BNB.
              </Text>
              <Button
                tag="a"
                href="https://bscbet.gitbook.io/bscbet/bscbet/our-games"
                w="6.5rem"
                textColor="black"
                bg="white"
                textSize="10px"
                h="2rem"
                m={{ t: "1rem" }}>
                LEARN MORE
              </Button>
            </Div>
          </Div>
          <Div
            w={{ xl: "25.5rem", xs: "90vw" }}
            h="13rem"
            rounded="20px"
            bg="#161618"
            d="block"
            m={{
              x: { xl: "1rem", xs: "none" },
              y: { xl: "0rem", xs: "1rem" },
            }}>
            <Div m="2rem">
              <Text textColor="white" textSize="34px" textWeight="bold">
                BUY $BSCB
              </Text>
              <Text textColor="white" textSize="18px" m={{ t: "0.5rem" }}>
                HOLD YOUR BAGS
              </Text>
              <Button
                textColor="black"
                tag="a"
                href="https://poocoin.app/tokens/0xe3616062fffcc5c4354d53bc1c8369265afd8654"
                w="6rem"
                bg="white"
                textSize="10px"
                h="2rem"
                m={{ t: "1rem" }}>
                BUY NOW
              </Button>
            </Div>
            {/* <Image src={bscbet} w="40px" p={{ l: "1.5rem" }} /> */}
          </Div>
          <Div
            w={{ xl: "25.5rem", xs: "90vw" }}
            h="13rem"
            rounded="20px"
            bg="linear-gradient(120deg, #ff0050, blue)"
            d="block"
            m={{
              x: { xl: "1rem", xs: "none" },
              y: { xl: "0rem", xs: "1rem" },
            }}>
            <Div m="2rem">
              <Text textColor="white" textSize="34px" textWeight="bold">
                NFTs
              </Text>
              <Text textColor="white" textSize="18px" m={{ t: "0.5rem" }}>
                COMING SOON
              </Text>
              <Button
                tag="a"
                href="https://bscbet.gitbook.io/bscbet/bscbet/nfts"
                w="6.5rem"
                textColor="black"
                bg="white"
                textSize="10px"
                h="2rem"
                m={{ t: "1rem" }}>
                LEARN MORE
              </Button>
            </Div>
            {/* <Image src={bscbet} w="40px" p={{ l: "1.5rem" }} /> */}
          </Div>
        </Div>

        {/* <Button id="learnButton" onClick={() => scrollTo("#about-us")}>
          Learn More
        </Button> */}
        {/* 
        <Div m={{ y: "3rem" }}>
          <RecentTransactionsTable />
        </Div> */}
      </Div>
    </div>
  );
};
