import { parseUnits } from "viem";
import { addresses } from "../../../contracts/src";

const bscfFlipAmounts = [
  { id: 0, name: "100 ARBF", value: parseUnits("1", 20) },
  { id: 1, name: "500 ARBF", value: parseUnits("5", 20) },
  { id: 2, name: "1000 ARBF", value: parseUnits("1", 21) },
  { id: 3, name: "5000 ARBF", value: parseUnits("5", 21) },
];
const usdcFlipAmounts = [
  { id: 0, name: "10 USDC", value: parseUnits("1", 19) },
  { id: 1, name: "500 USDC", value: parseUnits("5", 20) },
  { id: 2, name: "1000 USDC", value: parseUnits("1", 21) },
  { id: 3, name: "5000 USDC", value: parseUnits("5", 21) },
];

const bnbFlipAmounts = [
  { id: 0, name: ".01 BNB", value: parseUnits("1", 16) },
  { id: 1, name: ".05 BNB", value: parseUnits("5", 16) },
  { id: 2, name: ".1 BNB", value: parseUnits("1", 17) },
  { id: 3, name: ".25 BNB", value: parseUnits("25", 16) },
  { id: 4, name: ".5 BNB", value: parseUnits("5", 17) },
  // { id: 4, name: "1 BNB", value: parseUnits("1", 18) },
];

const stakeFilpAmounts = [
  { id: 0, name: "100 STAKE", value: parseUnits("1", 16) },
  { id: 1, name: "500 STAKE", value: parseUnits("5", 16) },
  { id: 2, name: "1000 STAKE", value: parseUnits("1", 17) },
  { id: 3, name: "2500 STAKE", value: parseUnits("25", 16) },
  { id: 4, name: "5000 STAKE", value: parseUnits("5", 17) },
  // { id: 4, name: "1 BNB", value: parseUnits("1", 18) },
];

const fairyFilpAmounts = [
  { id: 0, name: "100 BSCB", value: parseUnits("1", 16) },
  { id: 1, name: "500 BSCB", value: parseUnits("5", 16) },
  { id: 2, name: "1000 BSCB", value: parseUnits("1", 17) },
  { id: 3, name: "2500 BSCB", value: parseUnits("25", 16) },
  { id: 4, name: "5000 BSCB", value: parseUnits("5", 17) },
  // { id: 4, name: "1 BNB", value: parseUnits("1", 18) },
];

export const flipAmounts = [
  { token: addresses[97].bscF.address, values: bscfFlipAmounts },
  { token: addresses[97].bnb.address, values: bnbFlipAmounts },
  { token: addresses[56].bscF.address, values: bscfFlipAmounts },
  { token: addresses[56].bnb.address, values: bnbFlipAmounts },
  // { token: addresses[56].stake.address, values: stakeFilpAmounts },
  // { token: addresses[56].ftale.address, values: fairyFilpAmounts },
  // { token: addresses[56].dape.address, values: fairyFilpAmounts },
  // { token: addresses[56].flokizilla.address, values: fairyFilpAmounts },
  // { token: addresses[56].mbx.address, values: fairyFilpAmounts },
  // { token: addresses[56].apu.address, values: fairyFilpAmounts },
  // { token: addresses[56].qusdt.address, values: fairyFilpAmounts },
  // { token: addresses[56].mtinu.address, values: fairyFilpAmounts },
  // { token: addresses[56].xbusd.address, values: fairyFilpAmounts },
  // { token: addresses[56].paypal.address, values: fairyFilpAmounts },
  { token: addresses[421613].bscF, values: bscfFlipAmounts },
  { token: addresses[421613].arbf, values: bscfFlipAmounts },
  { token: addresses[421613].usdc, values: bscfFlipAmounts },
  { token: addresses[421613].bnb, values: bnbFlipAmounts },
];

export const headsOrTails = [
  { id: 0, name: "Red", value: 0, color: "red" },
  { id: 1, name: "Black", value: 1, color: "black" },
];
