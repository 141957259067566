import styled, { keyframes, css } from "styled-components";
import { Button } from "atomize";

export const DropDownContainer = styled("div")`
  width: 9rem;
  margin: 0 auto 20px auto;
`;

export const DropDownHeader = styled("div")`
  position: relative;
  display: flex;
  margin-top: 5px;
  height: 2rem;
  padding-top: 5px;
  align-items: center;
  padding-bottom: 5px;
  padding-left: 15px;
  box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-weight: bold;
  color: white;
  background: #212124;
  cursor: pointer;

  &:hover {
    background: #303030;
  }
`;

const rotateRed = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(1090deg);
}
`;
const rotateBlack = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(1070deg);
}
`;

export const WheelImage = styled.img`
  animation-name: ${({ isSpinning }) =>
    isSpinning &&
    css`
      ${rotateBlack}
    `};
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
`;

export const RouletteStopper = styled("div")`
  position: absolute;
  width: 10px;
  height: 25px;

  top: 5.5rem;
  right: 49.5vw;
`;
export const SpinButton = styled(Button)`
  margin-top: 10px;
`;

export const DropDownListContainer = styled("div")`
  position: relative;
`;

export const DropDownList = styled("ul")`
  position: absolute;
  display: block;
  top: 100%;
  width: 100%;
  margin-top: 0;
  padding-left: 10px;
  background: #121212;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  box-sizing: border-box;
  color: white;
  font-size: 12px;

  &:first-child {
    padding-top: 0.8em;
  }
  z-index: 1;
`;

export const ListItem = styled("li")`
  list-style: none;
  padding: 1px 5px 10px 5px;
  cursor: pointer;
  text-transform: uppercase;
`;

export const Caret = styled.div`
  position: absolute;
  transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "rotate(0deg)")};
  top: 45%;
  right: 10%;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white;
`;

export const OverCaret = styled.div`
  transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "rotate(0deg)")};
  width: 0;
  height: 0;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  position: relative;
  top: -10px;
  left: -5px;
`;
