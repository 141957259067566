import { useState, useEffect } from "react";
import { useAccount } from "wagmi";

import SoundOnIcon from "./SoundOn.png";
import Logo from "./transparent.png";
import { Affiliate } from "../Afilliate/Affiliate";
import { ConnectButton } from "@rainbow-me/rainbowkit";

import { Div, Row, Col, Button, Image, Icon } from "atomize";

export const TopHeader = ({ referralOpen }) => {
  const { address, isConnected } = useAccount();

  const [volumeImg, setVolumeImg] = useState(SoundOnIcon);
  const [affiliateOpen, setAffiliateOpen] = useState(false);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    // Detect initial screen size
    handleResize();

    // Add event listener to handle resizing
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const buttonStyles = {
    border: "2px solid white",
  };

  const toggleAffiliate = () => {
    setAffiliateOpen(true);
  };

  return (
    <Div
      pos="fixed"
      top="0"
      left="0"
      w="100vw"
      h="4rem"
      bg="black"
      shadow="1"
      p={{ x: "1rem", y: "1.5rem" }}
      d="flex"
      flexDir="row"
      align="center"
      justify="space-between"
      zIndex="100">
      <Row w="0">
        <Div h="5rem" d="flex" tag="a" href="/" cursor="pointer">
          <Image src={Logo} alt="Logo" w="80px" h="80px" />
        </Div>
      </Row>
      <Row d="flex" flexDir="row" p="1rem" textColor="black" align="center">
        <Col>
          <Div
            tag="a"
            href="https://t.me/BSCBetPortal"
            cursor="pointer"
            d="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="white"
              viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z" />
            </svg>
          </Div>
          {/* <Text
            tag="a"
            textSize="paragraph"
            textColor="black"
            cursor="pointer"
            m={{ x: "1rem" }}
          >
            Affiliate
          </Text> */}
        </Col>
        <Button
          m={{ l: { xl: "0.5rem", xs: "0.25rem" } }}
          h="2.5rem"
          bg="none"
          textColor="white"
          style={buttonStyles}
          tag="a"
          href="https://bscbet.gitbook.io/"
          cursor="pointer">
          {isMobile ? (
            <Icon name="Info" size="18px" color="white" />
          ) : (
            "Whitepaper"
          )}
        </Button>
        {isConnected && (
          <Button
            m={{ l: { xl: "0.5rem", xs: "0.5rem" } }}
            h="2.5rem"
            bg="none"
            textColor="white"
            style={buttonStyles}
            tag="a"
            onClick={toggleAffiliate}
            cursor="pointer">
            {isMobile ? (
              <Icon name="External" size="18px" color="white" />
            ) : (
              "Affiliate"
            )}
          </Button>
        )}

        {affiliateOpen && <Affiliate referralOpen={referralOpen} />}
        <Div m={{ l: "0.5rem" }}>
          <ConnectButton />
        </Div>
      </Row>
      {/* <MiddleHeader>Hello</MiddleHeader> */}

      {/* <SoundButton onClick={toggleVolume}>
          <SoundImage src={volumeImg} alt="sound" />
        </SoundButton> */}
    </Div>
  );
};
