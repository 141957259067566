import { useEffect, useState } from "react";
import { addresses } from "../../contracts/src";
import Web3 from "web3";
import MyAbi from "../Game/abi/bsccoinflip.json";
import { Div } from "atomize";
import { TxTable } from "./RecentTxStyles";

export function RecentTransactionsTable({ contractAddress, gameSymbol }) {
  const [transactions, setTransactions] = useState([]);
  const abi = MyAbi;
  const ca = addresses[56].bscCoinFlip.address;

  async function fetchTransactions() {
    // initialize web3 with the BSC provider
    const web3 = new Web3(
      "https://weathered-small-paper.bsc.discover.quiknode.pro/b6d6d14c47c066a9c44362e00141c74db5bfe15a/"
    );

    // get the contract instance using web3
    const contract = new web3.eth.Contract(
      abi, // replace with your contract ABI
      ca // replace with your contract address
    );

    // get the latest block number
    const latestBlockNumber = await web3.eth.getBlockNumber();

    // fetch the latest 10 transactions from the contract
    const blockNumberStart = Math.max(0, latestBlockNumber - 1000);
    const blockNumberEnd = latestBlockNumber;
    const events = await contract.getPastEvents("allEvents", {
      fromBlock: blockNumberStart,
      toBlock: blockNumberEnd,
    });

    // map the events to transaction objects
    const transactionObjects = events.map((event) => ({
      transactionHash: event.transactionHash,
      better: event.returnValues.better,
      from: event.sender,
      wager: event.returnValues.wager,
      token: event.returnValues.token,
      predictedOutcome: event.returnValues.predictedOutcome,
      winner: event.returnValues.winner,
      id: event.returnValues.id,
      gameStarted: event.event === "GameStarted",
    }));

    // update the state with the transaction objects
    setTimeout(() => {
      setTransactions(transactionObjects);
    }, 10000);
  }

  const gameTokens = Object.values(addresses[56]).map((token) =>
    token.address.toLowerCase()
  );
  console.log(gameTokens);

  useEffect(() => {
    fetchTransactions();
  }, [transactions]);

  return (
    <Div>
      <Div m={{ b: "0.5rem" }}>{/* <RecentGameOutcomes /> */}</Div>
      <Div bg="#161618" p={{ y: "0.5rem", x: "1rem" }} rounded="10px">
        <Div textColor="white" p={{ x: "1rem", y: "1rem" }} textWeight="bold">
          Recent Games
        </Div>
        <hr></hr>
        <TxTable>
          <thead>
            <th>Game</th>
            <th>User</th>
            <th>Amount</th>
            <th>Token</th>
            <th>Outcome</th>
          </thead>
          <tbody>
            {transactions
              .filter((transaction) => transaction.wager > 0)
              .filter(
                (transaction) =>
                  transaction.winner !== undefined &&
                  transaction.winner !== null
              )
              .sort((a, b) => b.id - a.id)
              .slice(0, 10)
              .map((transaction) => (
                <tr key={transaction.transactionHash}>
                  <td>Roulette</td>
                  <td>{transaction.better.slice(0, 6)} </td>
                  <td>
                    {transaction.token ===
                    "0x0000000000000000000000000000000000000000"
                      ? transaction.wager *
                        10 **
                          -(
                            gameTokens.includes(
                              transaction.token.toLowerCase()
                            ) &&
                            Object.values(addresses[56]).find(
                              (token) =>
                                token.address ===
                                transaction.token.toLowerCase()
                            ).decimals
                          )
                      : parseInt(
                          transaction.wager *
                            10 **
                              -(
                                gameTokens.includes(
                                  transaction.token.toLowerCase()
                                ) &&
                                Object.values(addresses[56]).find(
                                  (token) =>
                                    token.address ===
                                    transaction.token.toLowerCase()
                                ).decimals
                              )
                        )}
                    {}
                  </td>
                  <td>
                    {gameTokens.includes(transaction.token.toLowerCase()) &&
                      Object.values(addresses[56])
                        .find(
                          (token) =>
                            token.address === transaction.token.toLowerCase()
                        )
                        .symbol.toUpperCase()}
                  </td>
                  <td>{transaction.winner ? "Won" : "Lost"} </td>
                </tr>
              ))}
          </tbody>
        </TxTable>
      </Div>
    </Div>
  );
}
