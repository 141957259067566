import { useState, useEffect } from "react";
import { useAccount } from "wagmi";
import { addresses } from "../../contracts/src";
import { Centered } from "../Styles";

import { Div } from "atomize";
// import { RecentGameOutcomes } from "../RecentOutcomes/RecentOutcomes";
import { DoubleOrNothing } from "./DoubleOrNothing/DoubleOrNothing";
import { DropDown } from "../DropDown/DropDown";
import { Branding } from "../Branding/Branding";
import { Winnings } from "./Winnings/Winnings";
import { RecentTransactionsTable } from "../RecentTxs/RecentTxs.js";
import { StartPage } from "./StartPage";
import "./fadeIn.css";
import { Icon } from "atomize";

export const FlipGame = ({
  chainId,
  wrongChain,
  bscF,
  game,
  currentToken,
  onCurrentTokenChange,
}) => {
  const tokens = Object.values(addresses[chainId])
    .filter((token) => token.symbol) // Filter out tokens without a symbol
    .map((token) => token.symbol); // Convert symbols to uppercase
  const toggling = () => setIsOpen(!isOpen);

  const { address, isConnected } = useAccount();
  const [selectedToken, setSelectedToken] = useState("bnb");
  const [decimals, setDecimals] = useState("18");
  const [selectedTokenAddress, setSelectedTokenAddress] = useState(
    addresses[chainId].bscF
  );
  const [isOpen, setIsOpen] = useState(false);
  const [blurAmount, setBlurAmount] = useState(10);

  const onOptionClicked = (value) => () => {
    setSelectedToken(value);
    setIsOpen(false);
    onCurrentTokenChange(value);
  };

  const blurStyle = {
    filter: `blur(${blurAmount}px)`, // Apply the dynamic blur effect
    transition: "filter 1s ease-in",
  };

  useEffect(() => {
    if (isConnected) {
      setBlurAmount(0);
    } else if (!isConnected) {
      setBlurAmount(5);
    }
  }, [isConnected]);

  useEffect(() => {
    // Get the current URL
    const url = window.location.href;

    // Find the position of 'token/' in the URL
    const tokenIndex = url.indexOf("token/");

    // If 'token/' is found in the URL
    if (tokenIndex !== -1) {
      // Extract the token address from the URL
      const tokenAddressFromURL = url
        .substring(tokenIndex + 6, tokenIndex + 6 + 42)
        .toLowerCase(); // 6 is the length of 'token/'

      const matchingToken = Object.values(addresses[chainId]).find(
        (token) => token.address === tokenAddressFromURL
      );

      // Set selectedTokenAddress to the token address from the URL
      if (matchingToken) {
        // If a matching token is found, set selectedTokenAddress and selectedToken to the token address and symbol, respectively
        setSelectedToken(matchingToken.symbol);
      } else console.log("ADDRESSFROMURL: ", tokenAddressFromURL);
    } else {
      // If 'token/' is not found in the URL, set the default address

      setSelectedToken("bnb");
      console.log("FAILED: ");
    }
  }, []);

  useEffect(() => {
    if (selectedToken == "bscb") {
      setSelectedTokenAddress(addresses[chainId].bscF.address);
      setDecimals(9);
    } else {
      setSelectedTokenAddress(addresses[chainId][selectedToken].address);
      setDecimals(addresses[chainId][selectedToken].decimals);
    }
  }, [selectedToken, chainId]);

  const premiumStatus = addresses[chainId][selectedToken].premium;
  return (
    <Div>
      {!isConnected && (
        <Div
          className="fade-in-text"
          pos="fixed"
          d="flex"
          h="100vh"
          w="100vw"
          t="0"
          textColor="#AEAEB2"
          textSize={{
            xl: "50px",
            lg: "40px",
            md: "40px",
            sm: "30px",
            xs: "20px",
          }}
          style={{ zIndex: "1" }}
          justify="center"
          align="center">
          Please Connect Your Wallet
        </Div>
      )}
      <Div
        d="flex"
        w="100vw"
        minH="100vh"
        flexDir={{ xs: "column", lg: "row" }}
        flexWrap="wrap"
        justify="center"
        align="center"
        style={blurStyle}>
        <div>
          <Div className="fade-in-text">
            <Div m={{ t: { xs: "5rem", sm: "5rem", md: "5rem", lg: "80px" } }}>
              <DropDown
                options={tokens}
                onOptionClicked={onOptionClicked}
                selectedOption={selectedToken}
                isOpen={isOpen}
                toggling={toggling}
                shouldSpin={true}
              />
              {premiumStatus === "yes" && (
                <Branding
                  chainId={chainId}
                  gameToken={selectedTokenAddress}
                  gameSymbol={selectedToken}
                />
              )}
              <Winnings
                chainId={chainId}
                gameToken={selectedTokenAddress}
                gameSymbol={selectedToken}
              />
            </Div>
            <DoubleOrNothing
              gameToken={selectedTokenAddress}
              chainId={chainId}
              decimals={decimals}
              gameSymbol={selectedToken}
            />
          </Div>
        </div>
      </Div>
    </Div>
  );
};
