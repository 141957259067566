import bscCoinFlip from "./abis/bsccoinflip.json";
import bscFAbi from "./abis/bscF.json";
import usdcAbi from "./abis/usdc.json";
import stakeAbi from "./abis/stake.json";
import ftaleAbi from "./abis/ftale.json";
import dapeAbi from "./abis/dape.json";
import flokizillaAbi from "./abis/flokizilla.json";
import mbxAbi from "./abis/mbx.json";
import apuAbi from "./abis/apu.json";
import qusdtAbi from "./abis/qusdt.json";
import mtinuAbi from "./abis/mtinu.json";
import paypalAbi from "./abis/paypal.json";
import xbusdAbi from "./abis/xbusd.json";
import pepcAbi from "./abis/pepc.json";

const abis = {
  bscCoinFlip: bscCoinFlip,
  bscF: bscFAbi,
  usdc: usdcAbi,
  stake: stakeAbi,
  ftale: ftaleAbi,
  dape: dapeAbi,
  flokizilla: flokizillaAbi,
  mbx: mbxAbi,
  apu: apuAbi,
  qusdt: qusdtAbi,
  mtinu: mtinuAbi,
  paypal: paypalAbi,
  xbusd: xbusdAbi,
  pepc: pepcAbi,
};

export default abis;
