import React, { useState } from "react";
import { Modal, Button, Div, Text } from "atomize";

export function PolicyPopup(props) {
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleAccept = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      bg="none"
      p="0"
      m={{ t: { xl: "0", xs: "4rem" } }}
      d="flex"
      justify="center"
      align="center"
    >
      <Div
        bg="#161818"
        d="flex"
        flexDir="column"
        textAlign="center"
        color="white"
        w={{ xl: "32rem", md: "28rem", sm: "25rem", xs: "22rem" }}
        h={{ xl: "32rem", md: "34rem", xs: "38rem" }}
        rounded="10px"
        shadow="5"
        align="center"
      >
        <Text textColor="white" textSize="display1" p="30px">
          Policy
        </Text>
        <Text
          textColor="white"
          textSize="body"
          textAlign="left"
          p={{ x: "30px", y: "5px" }}
        >
          By accessing and using "BSCBet", you agree to be bound by the terms
          and conditions of this policy. If you do not agree to these terms,
          please do not use "BSCBet".
        </Text>
        <Text
          textColor="white"
          textSize="body"
          textAlign="left"
          p={{ x: "30px", y: "20px" }}
          m={{ b: "20px" }}
        >
          It is your responsibility to ensure that you are legally allowed to
          participate in online wagering activities in your jurisdiction. By
          using "BSCBet", you acknowledge that you are at least 18 years old or
          of legal age to wager cryptocurrency in your jurisdiction. We are not
          liable for any loss or damage resulting from your use of "BSCBet".
          Users participate at their own risk, and we do not guarantee any
          winnings or results. Please gamble responsibly and within your means.
        </Text>

        <Button
          w="7rem"
          // disabled={!props.checkedManagingWithState}
          onClick={handleAccept}
          p={{ x: "20px", y: "10px" }}
          bg="white"
          textColor="black"
        >
          I Accept
        </Button>
      </Div>
    </Modal>
  );
}

export default PolicyPopup;
