import React, { useState } from "react";
import { Div, Button, Icon, Modal, Text, Input } from "atomize";
import { useAccount } from "wagmi";
import { TopHeader, referralOpen } from "../Header/TopHeader";

export const Affiliate = ({ referralOpen }) => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleAccept = () => {
    setIsModalOpen(false);
  };

  const [copied, setCopied] = useState(false);
  const [{ data: account }] = useAccount({ fetchEns: false });
  const userAddress = account.address;
  const link = `https://bscbet.io/?ref=${userAddress}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
    setCopied(true);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={handleAccept}
      bg="none"
      p="0"
      m="0"
      d="flex"
      justify="center"
      align="center"
    >
      <Div
        bg="#161618"
        d="flex"
        flexDir="column"
        textAlign="left"
        color="white"
        w="28rem"
        h="16rem"
        rounded="10px"
        shadow="5"
        align="center"
      >
        <Button
          pos="fixed"
          m="none"
          p="none"
          right="3rem"
          top="3rem"
          onClick={handleAccept}
          bg="none"
          textColor="white"
          textWeight="bold"
          textSize="30px"
          textAlign="left"
        >
          x
        </Button>

        <Text
          textColor="white"
          textSize="24px"
          p={{ t: "30px", b: "1rem " }}
          textWeight="bold"
        >
          Affiliate
        </Text>
        <Div
          bg="#212124"
          w="26rem"
          h="10rem"
          rounded="10px"
          d="block"
          p={{ x: "2rem", t: "2rem" }}
        >
          <Div d="flex" flexDir="column" textAlign="left">
            <Text textColor="lime" textSize="20px" textWeight="bold">
              YOUR AFFILIATE LINK
            </Text>
            <Text textColor="gray900" textSize="12px" m={{ t: "0.25rem" }}>
              Earn 0.5% on all games played by players you refer.
            </Text>
          </Div>
          <Div
            d="flex"
            p={{ t: "1rem" }}
            w="100%"
            align="center"
            justify="center"
          >
            <Input
              bg="#121212"
              textColor="white"
              w="16rem"
              value={link}
              readOnly
            />
            <Button
              onClick={copyToClipboard}
              m={{ x: "1rem" }}
              bg="white"
              textColor="black"
            >
              {copied ? "Copied!" : "Copy"}
            </Button>
          </Div>
        </Div>
      </Div>
    </Modal>
  );
};

export default Affiliate;
