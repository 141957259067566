import React, { useState, useEffect, useCallback } from "react";
import { useAccount, useContractWrite } from "wagmi";
import { formatUnits } from "viem";

import { addresses, abis } from "../../../contracts/src";
import { Centered } from "../../Styles";
import { Div, Text, Button } from "atomize";
import Web3 from "web3";

export const Winnings = ({ chainId, gameToken, gameSymbol, gameFinished }) => {
  const { address, isConnected } = useAccount();
  const [unclaimedBSCF, setUnclaimedBSCF] = useState(0);
  const [unclaimedBNB, setUnclaimedBNB] = useState(0);
  const [unclaimedTokens, setUnclaimedTokens] = useState(0);

  const bscNodeUrl =
    "https://weathered-small-paper.bsc.discover.quiknode.pro/b6d6d14c47c066a9c44362e00141c74db5bfe15a/";
  const web3 = new Web3(bscNodeUrl);

  const [tokenBalance, setTokenBalance] = useState(0);

  const gameContract = new web3.eth.Contract(
    abis.bscCoinFlip,
    addresses[chainId].bscCoinFlip.address
  );

  useEffect(() => {
    refreshTokenBalance();
  }, [address, chainId]);

  const refreshTokenBalance = async () => {
    if (address) {
      try {
        const tokenBalance = await gameContract.methods
          .balanceOf(address)
          .call();
        setTokenBalance(formatUnits(tokenBalance.toString()));
      } catch (error) {
        console.log(error);
      }
    }
  };

  // useEffect(() => {
  //   if (game && game.signer) {
  //     game.on("GameFinished", gameFinishedListener);
  //     game.on("PayoutComplete", payoutCompleteListener);
  //   }

  //   return () => {
  //     game.off("GameFinished", gameFinishedListener);
  //     game.off("PayoutComplete", payoutCompleteListener);
  //   };
  // }, [gameFinishedListener, payoutCompleteListener]);

  useEffect(() => {
    refreshTokenBalance();
    refreshWinnings();
  }, [gameFinished, gameToken]);

  const refreshWinnings = async () => {
    if (address) {
      try {
        const tokenAddress =
          addresses[chainId][gameSymbol.toLowerCase()].address;
        const bscf = await gameContract.methods
          ._winnings(address, tokenAddress)
          .call();
        const bnb = await gameContract.methods
          ._winnings(address, addresses[chainId].bnb.address)
          .call();

        setUnclaimedBSCF(
          parseInt(bscf) /
            10 ** addresses[chainId][gameSymbol.toLowerCase()].decimals
        );

        setUnclaimedBNB(parseInt(bnb) / 10 ** 18);
        console.log(unclaimedBNB);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    refreshWinnings();
  }, [address, chainId]);

  // const pollingInterval = setInterval(() => {
  //   refreshWinnings();
  // }, 20000);

  // useEffect(() => {
  //   if (address) {
  //     game.on("GameFinished", gameFinishedListener);
  //     game.on("PayoutComplete", payoutCompleteListener);
  //   }

  //   return () => {
  //     game.off("GameFinished", gameFinishedListener);
  //     game.off("PayoutComplete", payoutCompleteListener);
  //   };
  // }, [gameFinishedListener, payoutCompleteListener]);

  const { write: claimWinnings, data: enterGameData } = useContractWrite({
    address: addresses[chainId].bscCoinFlip.address,
    abi: abis.bscCoinFlip,
    functionName: "claimWinnings",
    args: [gameToken],
    onSettled(data, error) {
      console.log("Settled", { data, error });
    },
  });

  return (
    <Div>
      {/* <Text>
        <Centered>
          <Text
            textColor="white"
            textWeight="bold"
            justify="centered"
            textSize="18px"
          >
            Withdraw
          </Text>
        </Centered>
      </Text> */}

      <Div
        d="flex"
        flexDir="row"
        justify="center"
        align="center"
        m="1rem"
        h="2.5rem">
        <Div
          d="flex"
          flexDir="row"
          bg="black900"
          textColor="white"
          h="2.8rem"
          justify="center"
          align="center"
          p="1rem"
          rounded="5px">
          <Div
            w="16px"
            h="16px"
            m={{ r: ".5rem" }}
            justify="center"
            align="center"
            d="flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="0.8rem"
              viewBox="0 0 512 512"
              fill="gold">
              <path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2l0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5V176c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336V300.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4V304v5.7V336zm32 0V304 278.1c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5V272c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5V432c0 44.2-86 80-192 80S0 476.2 0 432V396.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z" />
            </svg>
          </Div>
          <Text>
            {gameToken !== addresses[chainId].bnb.address
              ? `${gameSymbol.toUpperCase()}: ${unclaimedBSCF}`
              : `BNB: ${unclaimedBNB}`}
          </Text>
        </Div>
        <Button
          onClick={() => {
            if (gameSymbol === "bscb") {
              // If it's equal to 0x0, call claimWinnings on bnb address
              claimWinnings();
            } else {
              // If it's not equal to 0x0, call claimWinnings on bscf address
              claimWinnings();
              console.log(addresses[chainId][gameSymbol.toLowerCase()].address);
            }
          }}
          justify="center"
          align="center"
          h="2.5rem"
          m={{ x: "1rem" }}
          bg="#32CD32">
          <Div d="flex" align="center" justify="center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16">
              <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z" />
            </svg>
            <Text m={{ l: "0.5rem" }}>Withdraw</Text>
          </Div>
        </Button>
      </Div>
    </Div>
  );
};
