import React, { useState, useEffect, useCallback } from "react";
import {
  useAccount,
  useContractRead,
  useContractWrite,
  useWaitForTransaction,
  useContractEvent,
} from "wagmi";
import { parseUnits } from "viem";
import ConfettiGenerator from "confetti-js";
import Web3 from "web3";
import { addresses, abis } from "../../../contracts/src";

// import { ApprovalButton } from "./ApprovalButton";
import { flipAmounts, headsOrTails } from "./FlipAmounts";
import { Centered } from "../../Styles";
import {
  DoubleOrNothingBtn,
  FlipContainer,
  GameButton,
  ColorButton,
} from "./FlipStyles";
import PolicyPopup from "../../Header/policy";
import {
  RecentTransactionsTable,
  RecentTxContainer,
} from "../../RecentTxs/RecentTxs";
import { CurrentGames } from "../../RecentTxs/CurrentGames";
// import { RecentGameOutcomes } from "../../RecentOutcomes/RecentOutcomes";

import { Div, Icon, Text, Row, Button, Input, Notification } from "atomize";
// import { DropDown } from "../../DropDown/DropDown";
import { RouletteWheel } from "../Roulette/RouletteWheel";
// import { Winnings } from "../Winnings/Winnings";

export const DoubleOrNothing = ({
  gameToken,
  bscF,
  game,
  decimals,
  gameSymbol,
  chainId,
}) => {
  const bnb = "0x0000000000000000000000000000000000000000";
  // const requiredAllowance = acc("5", 23);

  const { address, isConnected } = useAccount();
  const [activeAmountButton, setActiveAmountButton] = useState(0);
  const [activeChoiceButton, setActiveChoiceButton] = useState(-1);
  const [gameContractAddress, setGameContractAddress] = useState(
    addresses[chainId][gameSymbol].address
  );
  const [isLive, setIsLive] = useState();
  const [approved, setApproved] = useState(false);
  // const [gameFlipAmounts, setGameFlipAmounts] = useState(null);
  const [gameReady, setGameReady] = useState(false);
  const [gameError, setGameError] = useState(null);
  const [gameStarted, setGameStarted] = useState(false);
  const [gameFinished, setGameFinished] = useState(false);
  const [showError, setShowError] = useState(false);
  const [winner, setWinner] = useState(false);
  const [gameId, setGameId] = useState();
  const [txHash, setTxHash] = useState();
  const [gameValid, setGameValid] = useState(false);
  const [result, setResult] = useState(0);
  const [flipAmount, setFlipAmount] = useState();
  const [displayResult, setDisplayResult] = useState(false);
  const [pickedColor, setPickedColor] = useState("");
  const [activeColor, setActiveColor] = useState("");
  const [gameValue, setGameValue] = useState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [wheelColor, setWheelColor] = useState("");
  const [balance, setBalance] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [customAmount, setCustomAmount] = useState(0);
  const [maxBet, setMaxBet] = useState(0);

  var intervalId;

  const handleAmountButtonClick = (event) => {
    setActiveAmountButton(Number(event.target.value));
  };

  const handleChoiceButtonClick = (event) => {
    const newValue = Number(event.target.value);
    setActiveChoiceButton(newValue);
    setIsLive(1);

    console.log("activeamt", newValue);

    console.log("activeamt", activeChoiceButton);
    console.log("activecolor", activeColor);
  };

  useEffect(() => {
    if (gameValue === 0) {
      setPickedColor("Red");
    } else if (gameValue === 1) {
      setPickedColor("Black");
    }
    console.log(pickedColor);
    enterGame();
  }, [gameValue]);

  const handleGameStart = async (gameColor) => {
    console.log("YOU PICKED: ", pickedColor);
    // let newWheelColor = "Red"; // Default value

    // if (gameColor) {
    //   if (pickedColor === "Red") {
    //     newWheelColor = "Red";
    //   } else if (pickedColor === "Black") {
    //     newWheelColor = "Black";
    //   }
    // } else {
    //   if (pickedColor === "Red") {
    //     newWheelColor = "Black";
    //   } else if (pickedColor === "Black") {
    //     newWheelColor = "Red";
    //   }
    // }
  };

  useEffect(() => {
    setCustomAmount();
  }, [gameSymbol]);

  React.useEffect(() => {
    const confettiSettings = {
      target: "canvas",
      colors: [
        [162, 210, 255],
        [131, 56, 236],
        [230, 57, 70],
        [93, 211, 158],
      ],
    };

    const confetti = new ConfettiGenerator(confettiSettings);
    if (winner) {
      setTimeout(() => {
        confetti.render();
      }, 8000);
    }
    return () => confetti.clear();
  }, [winner]); // add the var dependencies or not

  const beginGame = (win, gameId) => {
    checkGameFinished(gameId, win);
    // setWinner(win);
    // handleWheelColor(win);
  };

  const checkGameFinished = (gameId, userWin) => {
    let intervalId;

    intervalId = setInterval(async () => {
      try {
        const gameContract = new web3.eth.Contract(
          abis.bscCoinFlip,
          addresses[chainId].bscCoinFlip.address
        );
        const userGame = await gameContract.methods._games(gameId).call();
        console.log(userGame);

        if (userGame.finished) {
          // setWinner(userGame.winner);
          // setGameFinished(true);
          console.log("SETTING GAME NOW WOOHOO");
          console.log("OUTCOMES winner: ", typeof userGame.winner);
          console.log("OUTCOMES predicted: ", typeof userGame.predictedOutcome);
          const predictedInt = parseInt(userGame.predictedOutcome);
          console.log("GAME INT ", predictedInt);

          if (userGame.winner && predictedInt === 0) {
            console.log("GAME SETTING 1");
            setWheelColor("Red");
            setWinner(userWin);
            setGameFinished(true);
          } else if (userGame.winner && predictedInt === 1) {
            console.log("GAME SETTING 2");
            setWheelColor("Black");
            setWinner(userWin);
            setGameFinished(true);
          } else if (userGame.winner === false && predictedInt === 0) {
            console.log("GAME SETTING 3");
            setWheelColor("Black");
            setWinner(userWin);
            setGameFinished(true);
          } else if (userGame.winner === false && predictedInt === 1) {
            console.log("GAME SETTING 4");
            setWheelColor("Red");
            setWinner(userWin);
            setGameFinished(true);
          }

          // 0 is loss, 1 is win

          clearInterval(intervalId);
        }
      } catch (err) {
        console.log(err);
      }
    }, 500);
  };

  const handleWheelColor = async (userWin) => {
    console.log("I SET THE WINNER: ", userWin);
    console.log("YOU PICKED: ", pickedColor);

    // if (userWin) {
    //   if (pickedColor === "Red") {
    //     newWheelColor = "Red";
    //   } else if (pickedColor === "Black") {
    //     newWheelColor = "Black";
    //   }
    // } else {
    //   if (pickedColor === "Red") {
    //     newWheelColor = "Black";
    //   } else if (pickedColor === "Black") {
    //     newWheelColor = "Red";
    //   }
    // }

    // Set the state after all logic
    // setGameFinished(true);
    // setDisplayResult(true);

    console.log("WINNER PICK: ", wheelColor);
  };
  // useEffect(() => {
  //   console.log("I SET THE WINNER: ", winner);
  //   console.log("YOU PICKED: ", pickedColor);
  //   if (winner && pickedColor === "Red") {
  //     setWheelColor("Red");
  //   } else if (winner && pickedColor === "Black") {
  //     setWheelColor("Black");
  //   } else if (!winner && pickedColor === "Red") {
  //     setWheelColor("Black");
  //   } else if (!winner && pickedColor === "Black") {
  //     setWheelColor("Red");
  //   }
  //   console.log("WINNER PICK: ", wheelColor);
  // }, [winner, gameFinished, beginGame]); // add the var dependencies or not

  // useEffect(() => {
  //   const showAllowances = async () => {
  //     if (game && bscF && account && bscF.signer) {
  //       try {
  //         if (gameToken !== bnb) {
  //           const allowance = await bscF.allowance(
  //             account.address,

  //             game.address
  //           );
  //           setApproved(
  //             parseInt(allowance._hex, 16) >
  //               parseInt(requiredAllowance._hex, 16)
  //           );
  //           console.log("BSCB has been Approved!");
  //         } else {
  //           setApproved(true);
  //           console.log("BSCB Approved!");
  //         }
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     }
  //   };
  //   showAllowances();
  // }, [account, game, bscF]);

  // const approvedListener = async (owner, spender, value) => {
  //   try {
  //     if (owner === bscF.signer) {
  //       const allowance = await bscF.allowance(account.address, game.address);
  //       setApproved(allowance._hex > requiredAllowance._hex);
  //       console.log("Your BSCB is Approved!");
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  useEffect(() => {
    // Fetch BNB balance when the component mounts
    fetchBalance();
  }, []);

  useEffect(() => {
    // Fetch BNB balance when the component mounts
    fetchBalance();
  }, [gameStarted]);

  useEffect(() => {
    // Fetch BNB balance when the component mounts
    fetchTokenBalance();
    console.log("balance set");
    fetchMaxBet();
  }, [gameToken, gameStarted]);

  const bscNodeUrl =
    "https://weathered-small-paper.bsc.discover.quiknode.pro/b6d6d14c47c066a9c44362e00141c74db5bfe15a/";
  const web3 = new Web3(bscNodeUrl);

  const fetchBalance = async () => {
    try {
      // Replace 'YOUR_BSC_NODE_URL' with the Binance Smart Chain node URL

      const walletAddress = address;
      const balanceInWei = await web3.eth.getBalance(walletAddress);
      const balanceInBnb = parseFloat(
        web3.utils.fromWei(balanceInWei, "ether")
      );

      setBalance(balanceInBnb.toString().slice(0, 6));
    } catch (error) {
      console.error("Error fetching BNB balance:", error);
    }
  };

  const fetchTokenBalance = async () => {
    try {
      // Replace 'YOUR_BSC_NODE_URL' with the Binance Smart Chain node URL
      const bscNodeUrl =
        "https://weathered-small-paper.bsc.discover.quiknode.pro/b6d6d14c47c066a9c44362e00141c74db5bfe15a/";
      const web3 = new Web3(bscNodeUrl);

      const selectedAbi = gameSymbol === "bscb" ? abis.bscF : abis[gameSymbol];

      // Replace 'YOUR_WALLET_ADDRESS' with your actual BNB wallet address
      const walletAddress = address;
      const contract = new web3.eth.Contract(selectedAbi, gameToken);
      const balanceInWei = await contract.methods
        .balanceOf(walletAddress)
        .call();
      const decimals =
        gameSymbol === "bscb"
          ? addresses[chainId].bscF.decimals
          : addresses[chainId][gameSymbol].decimals;
      const balanceInToken = parseInt(balanceInWei / 10 ** decimals);

      setTokenBalance(balanceInToken);
    } catch (error) {
      console.error("Error fetching Token balance:", error);
    }
  };

  const fetchMaxBet = async () => {
    try {
      // Replace 'YOUR_BSC_NODE_URL' with the Binance Smart Chain node URL
      const bscNodeUrl =
        "https://weathered-small-paper.bsc.discover.quiknode.pro/b6d6d14c47c066a9c44362e00141c74db5bfe15a/";
      const web3 = new Web3(bscNodeUrl);

      const selectedAbi = abis.bscCoinFlip;
      const contract = addresses[chainId].bscCoinFlip.address;

      const bscBetContract = new web3.eth.Contract(selectedAbi, contract);

      // Replace 'YOUR_WALLET_ADDRESS' with your actual BNB wallet address

      const addressToQuery =
        gameSymbol === "bscb"
          ? addresses[chainId].bscF.address
          : addresses[chainId][gameSymbol].address;

      const decimals =
        gameSymbol === "bscb"
          ? addresses[chainId].bscF.decimals
          : addresses[chainId][gameSymbol].decimals;

      if (bscBetContract) {
        bscBetContract.methods
          ._maxBetForToken(addressToQuery)
          .call()
          .then((result) => {
            const gameMaxBet = parseFloat(result / 10 ** decimals);
            setMaxBet(gameMaxBet);
            console.log("MAX BET HAS BEEN SET: ", result);
          })
          .catch((error) => {
            console.error("CANT FETCH BET:", error);
          });
      }

      // const decimals = addresses[chainId].bscF.decimals;
      // const balanceInToken = parseInt(balanceInWei / 10 ** decimals);
    } catch (error) {
      console.error("Error fetching maximum bet:", error);
    }
  };
  // const checkGameFinished = (gameId) => {
  //   let intervalId;

  //   intervalId = setInterval(async () => {
  //     if (gameValid) {
  //       try {
  //         const gameContract = new web3.eth.Contract(
  //           abis.bscCoinFlip,
  //           addresses[chainId].bscCoinFlip.address
  //         );
  //         const userGame = await gameContract.methods._games(gameId).call();
  //         console.log(userGame);

  //         if (userGame.finished) {
  //           setWinner(userGame.winner);
  //           setGameFinished(true);

  //           if (userGame.winner) {
  //             setResult(userGame.predictedOutcome);
  //             console.log(result);
  //           } else {
  //             setResult((userGame.predictedOutcome + 1) % 2);
  //             console.log(result);
  //           }

  //           // 0 is loss, 1 is win

  //           clearInterval(intervalId);
  //         }
  //       } catch (err) {
  //         console.log(err);
  //       }
  //     }
  //   }, 500);
  // };

  console.log("IS GAME FINISHED?: ", gameFinished);

  // const { data, isError, isLoading } = useContractRead({
  //   address: addresses.bscCoinFlip[chainId].address,
  //   abi: abis.bscCoinFlip,
  //   functionName: "_games",
  //   args: [gameId],
  //   select: (data: ) => transform(data),
  // });

  useEffect(() => {
    if (gameToken === bnb) {
      if (balance < customAmount) {
        setShowError(true);
      } else if (balance > customAmount && gameValue !== -1) {
        // startGame();

        console.log("GAME VALUE: ", gameValue);
      } else {
        console.log("GAME ERROR");
      }
    } else {
      if (tokenBalance < customAmount) {
        setShowError(true);
      } else if (tokenBalance > customAmount && gameValue !== -1) {
        // startGame();
      } else {
        // setTimeout(() => {
        //   startGame();
        // }, 2000);
        console.log("GAME ERROR");
      }
    }
  }, [gameValue]);

  useEffect(() => {
    setShowConfirmation(true);
  }, [gameStarted]);

  // useEffect(() => {
  //   if (bscF && bscF.signer) {
  //     bscF.on("Approval", approvedListener);
  //   }

  //   return () => {
  //     if (bscF && bscF.signer) {
  //       bscF.off("Approval", approvedListener);
  //     }
  //   };
  // }, [bscF]);

  // const gameFinishedListener = useCallback(
  //   (better, token, winner, wager, id) => {
  //     console.log("Game finished: ", better, token, winner, wager, id);
  //     if (game.signer._address === better && id === gameId) {
  //       if (intervalId) {
  //         clearInterval(intervalId);
  //         intervalId = null;
  //       }
  //       setWinner(winner);
  //       setGameFinished(true);
  //     }
  //   },
  //   [gameId, intervalId]
  // );

  // useEffect(() => {
  //   const amounts = flipAmounts.find((game) => game.token === gameToken);
  //   setGameFlipAmounts(amounts.values);
  // }, [gameToken]);

  useEffect(() => {
    if (gameFinished) {
      const timeoutId = setTimeout(() => {
        setDisplayResult(true);
      }, 7000);

      const newTimeoutId = setTimeout(() => {
        startOver();
      }, 12000);

      return () => clearTimeout(timeoutId, newTimeoutId);
    }
    setShowConfirmation(false);
  }, [gameFinished]);

  // useEffect(() => {
  //   if (game && gameValid) {
  //     game.on("GameFinished", gameFinishedListener);
  //   }

  //   return () => {
  //     game.off("GameFinished", gameFinishedListener);
  //   };
  // }, [gameFinishedListener]);

  useEffect(() => {
    if (activeAmountButton >= 0 && activeChoiceButton >= 0) {
      setGameReady(true);
    } else {
      setGameReady(false);
    }
  }, [activeAmountButton, activeChoiceButton]);

  useEffect(() => {
    convertToWei();
  }, [customAmount, isConnected]);
  console.log(flipAmount);

  async function convertToWei() {
    if (customAmount > 0) {
      const userStake = parseUnits(customAmount.toString(), decimals);
      await setFlipAmount(userStake);
    }
    if (customAmount === 0 || customAmount === null) {
      await setFlipAmount(1);
    }
  }

  const { write: enterGame, data: enterGameData } = useContractWrite({
    address: addresses[chainId].bscCoinFlip.address,
    abi: abis.bscCoinFlip,
    functionName: "enterGame",
    value: gameToken === addresses[chainId].bnb.address ? flipAmount : "0",
    args: [
      flipAmount,
      gameValue,
      gameToken,
      "0x0000000000000000000000000000000000000000",
    ],
    onSettled(data, error) {
      console.log("Settled", { data, error });
    },
  });
  // const waitForTransaction = useWaitForTransaction({
  //   hash: enterGameData?.hash,
  //   onSuccess(data) {
  //     const gameTxHash = enterGameData?.hash;
  //     console.log("waited successfully. Game TX: ", gameTxHash);
  //   },
  // });

  useContractEvent({
    address: addresses[chainId].bscCoinFlip.address,
    abi: abis.bscCoinFlip,
    eventName: "GameStarted",
    listener(gameStartedEvent) {
      console.log("GAME STARTED EVENT: ", gameStartedEvent[0].args);
      if (gameStartedEvent[0].args.better === address) {
        console.log("YOU_PLAYED: ", gameStartedEvent[0].args.better);
        const id = gameStartedEvent[0].args.id;
        setGameStarted(true);
        setGameId(id);
      }
    },
  });
  console.log("YOUR GAME ID: ", gameId);

  useContractEvent({
    address: addresses[chainId].bscCoinFlip.address,
    abi: abis.bscCoinFlip,
    eventName: "GameFinished",
    listener(gameFinishedEvent) {
      console.log("GAME FINISHED EVENT: ", gameFinishedEvent[0].args);
      console.log("GAME ID: ", gameFinishedEvent[0].args.id);
      console.log("PLAYER'S ID: ", gameId);
      if (gameFinishedEvent[0].args.better === address) {
        console.log(
          "ADDRESS MATCHED. COMPLETING GAME AT ADDRESS: ",
          gameFinishedEvent[0].args.better
        );

        beginGame(
          gameFinishedEvent[0].args.winner,
          gameFinishedEvent[0].args.id
        );
      }
    },
  });
  console.log("YOUR GAME ID: ", gameId);
  console.log("WINNER", winner);

  const startOver = () => {
    setGameId();
    setGameValue(-1);
    setGameFinished(false);
    setGameStarted(false);
    setWinner(false);
    setGameError(null);
    setActiveAmountButton(0);
    setGameStarted(false);
    setDisplayResult(false);
    setGameValid(false);
    // setPickedColor("");
    setWheelColor("");
  };

  const handleInputChange = (event) => {
    const inputValue = parseFloat(event.target.value);
    setActiveAmountButton(inputValue);
  };

  const handleCustomAmountChange = (event) => {
    setCustomAmount(event.target.value);
  };

  return (
    <Div
      d="flex"
      flexDir="column"
      justify="center"
      align="center"
      m={{ b: "2rem" }}>
      {isConnected && <PolicyPopup />}

      {showError && (
        <Notification
          bg="danger800"
          isOpen={showError}
          onClick={() => setShowError(false)}
          cursor="pointer"
          prefix={
            <Icon name="Cross" color="white" size="18px" m={{ r: "0.5rem" }} />
          }>
          Your bet amount is greater than your balance!
        </Notification>
      )}
      {showConfirmation && (
        <Notification
          bg="#212124"
          isOpen={showConfirmation}
          onClick={() => setShowConfirmation(false)}
          cursor="pointer"
          prefix={
            <Icon name="Cross" color="white" size="18px" m={{ r: "0.5rem" }} />
          }>
          Confirming your transaction...
        </Notification>
      )}
      <Div
        d="flex"
        flexDir={{ xl: "row", xs: "column" }}
        align="center"
        justify="center">
        <Div
          d="flex"
          flexDir="column"
          align="center"
          bg="none"
          h="13rem"
          w={{ xl: "20rem", md: "20rem", xs: "20rem" }}
          m="10px"
          rounded="15px">
          <Div p="0" m="0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1rem"
              viewBox="0 0 320 512"
              fill="gray">
              <path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" />
            </svg>
          </Div>
          <Div
            h="200px"
            rounded="50%"
            hoverShadow="4"
            transition="box-shadow 0.3s ease-in-out"
            style={{
              boxShadow: "0 0 5px rgba(0, 0, 255, 0.4)",
              transition: "box-shadow 0.3s ease-in-out",
            }}>
            <RouletteWheel
              spinToColor={wheelColor}
              shouldSpin={gameFinished}
              gameSymbol={gameSymbol}
              chainId={chainId}
            />
          </Div>
        </Div>
        <Div>
          <Div
            d="flex"
            flexDir="column"
            shadow="4"
            border="white"
            justify="center"
            align="center"
            p={{ x: "0rem", t: "1rem" }}>
            {/* <Winnings
                game={game}
                bscF={bscF}
                chainId={chainId}
                gameToken={gameToken}
                gameSymbol={gameSymbol}
              /> */}
            <Div d="flex" flexDir="column">
              {/* <Centered spaced={false}>I Like</Centered> */}
              <Div p={{ t: { xl: "0rem", xs: "0rem" } }} d="flex">
                <Text
                  textColor="gray900"
                  textSize="10px"
                  textAlign="left"
                  p={{ y: "5px" }}
                  w="50%">
                  Max Bet:{" "}
                  <span style={{ color: "white" }}>
                    {maxBet} {gameSymbol.toUpperCase()}
                  </span>
                </Text>
                <Div w="50%" p={{ y: "5px" }}>
                  <Text textColor="gray900" textSize="10px" textAlign="right">
                    Balance:{" "}
                    <span style={{ color: "lime" }}>
                      {" "}
                      {gameSymbol === "bnb" ? balance : tokenBalance}{" "}
                      {gameSymbol.toUpperCase()}
                    </span>
                  </Text>
                </Div>
              </Div>

              <Div>
                <Div bg="#212124" rounded="5px" h="3rem" w="100%" d="flex">
                  <Div
                    d="flex"
                    align="center"
                    justify="center"
                    m={{ l: "1rem" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 512 512"
                      fill="gold">
                      <path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2l0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5V176c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336V300.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4V304v5.7V336zm32 0V304 278.1c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5V272c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5V432c0 44.2-86 80-192 80S0 476.2 0 432V396.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z" />
                    </svg>
                  </Div>
                  <Input
                    type="number"
                    placeholder="Enter bet amount..."
                    value={customAmount}
                    onChange={handleCustomAmountChange}
                    onFocus={() => setCustomAmount("")}
                    bg="none"
                    textColor="white"
                    border="none"
                    h="3rem"
                    w={{ xl: "16rem", lg: "18rem", md: "21rem", xs: "11rem" }}
                  />
                  <Div
                    d="flex"
                    align="center"
                    justify="right"
                    w="100%"
                    p={{ r: "0.25rem" }}>
                    <Button
                      bg="#404040"
                      w="1rem"
                      h="1.5rem"
                      textSize="10px"
                      textWeight="bold"
                      onClick={() => setCustomAmount(customAmount / 2)}>
                      /2
                    </Button>
                    <Button
                      m={{ l: "0.25rem" }}
                      bg="#404040"
                      w="1rem"
                      h="1.5rem"
                      textSize="10px"
                      textWeight="bold"
                      onClick={() => setCustomAmount(customAmount * 2)}>
                      x2
                    </Button>
                    <Button
                      m={{ l: "0.25rem" }}
                      bg="#404040"
                      w="1rem"
                      h="1.5rem"
                      textSize="8px"
                      textWeight="bold"
                      onClick={() =>
                        setCustomAmount(
                          gameSymbol === "bnb"
                            ? balance < maxBet
                              ? balance - 0.001
                              : maxBet
                            : tokenBalance < maxBet
                            ? tokenBalance
                            : maxBet
                        )
                      }>
                      MAX
                    </Button>
                  </Div>
                </Div>
              </Div>
              <Div
                textColor="gray900"
                textSize="body"
                textWeight="bold"
                p={{ l: "1rem" }}>
                {/* Your Color */}
              </Div>

              <br />
              <Div>
                {gameStarted && (
                  <Div
                    d="flex"
                    flexDir="column"
                    align="center"
                    justify="center">
                    {!gameFinished && !gameError && (
                      <Div>
                        {gameId === -1 && (
                          <Text
                            m={{ y: "0.5rem" }}
                            textColor="white"
                            textWeight="bold"
                            textSize="14px">
                            Waiting For Confirmation
                          </Text>
                        )}
                        {gameId >= 0 && (
                          <Div
                            d="flex"
                            flexDir="column"
                            align="center"
                            justify="center">
                            {/* <Text spaced={false}>Game {gameId} Started</Text> */}
                            {/* <Text textColor="white" textWeight="bold">
                                You Chose:{" "}
                                {headsOrTails[activeChoiceButton].name}
                              </Text> */}

                            <Icon name="Loading3" color="white" size="100px" />
                          </Div>
                        )}
                      </Div>
                    )}
                    {!gameFinished && gameError && (
                      <Div
                        d="flex"
                        flexDir="column"
                        align="center"
                        justify="center">
                        <Text textColor="white" textWeight="bold">
                          ERROR WHEN STARTING GAME
                        </Text>
                        <Text
                          textColor="white"
                          textSize="12px"
                          textWeight="bold">
                          {gameError.message}
                          <Text
                            tag="a"
                            color="white"
                            textWeight="bold"
                            href="t.me/neutroncrypto"
                            textSize="14px">
                            DM TG Support
                          </Text>
                        </Text>
                        <FlipContainer>
                          {/* <GameButton onClick={startOver}>
                              Try Again
                            </GameButton> */}
                        </FlipContainer>
                      </Div>
                    )}
                    {gameFinished && (
                      <div>
                        {/* <Centered spaced={false}>
                  You Chose: {headsOrTails[activeChoiceButton].name}
                </Centered> */}
                        {displayResult && (
                          <div>
                            <Centered spaced={false}>
                              {winner ? (
                                <Text
                                  m={{ y: "1rem" }}
                                  textColor="green"
                                  textSize="title"
                                  textWeight="bold">
                                  Winner!
                                </Text>
                              ) : (
                                <Text
                                  m={{ y: "1rem" }}
                                  textColor="red"
                                  textSize="title"
                                  textWeight="bold">
                                  You Lose!
                                </Text>
                              )}
                            </Centered>
                          </div>
                        )}
                        {/* <FlipContainer>
                    <Button m={{ t: "1rem" }} onClick={startOver}>
                      Spin Again
                    </Button>
                  </FlipContainer> */}
                      </div>
                    )}
                  </Div>
                )}
              </Div>
            </Div>
            <Div d="flex" align="center" justify="center">
              {/* {!approved && !gameStarted && (
                  <ApprovalButton
                    bscF={bscF}
                    game={game}
                    gameSymbol={gameSymbol}
                  />
                )} */}
            </Div>
          </Div>
        </Div>
      </Div>
      <Div m={{ y: "0.5rem" }}>
        {/* <Div d="flex" align="center">
          <Text textAlign="left" textColor="gray800">
            Last 10 Spins
          </Text>
          <Div
            w="15px"
            h="15px"
            bg="red"
            rounded="50%"
            m={{ x: "0.75rem" }}></Div>
          <Text textAlign="left" textColor="white">
            4
          </Text>
          <Div
            w="15px"
            h="15px"
            bg="black"
            rounded="50%"
            m={{ x: "0.75rem" }}></Div>
          <Text textAlign="left" textColor="white">
            6
          </Text>
        </Div> */}
      </Div>
      <Div
        d="flex"
        flexDir={{ xl: "row", lg: "row", md: "column", xs: "column" }}
        align="center"
        justify="center">
        <Button
          w={{
            xl: "27rem",
            lg: "27rem",
            md: "30rem",
            sm: "30rem",
            xs: "20rem",
          }}
          h="3rem"
          bg={
            addresses[chainId][gameSymbol].premium === "yes"
              ? addresses[chainId][gameSymbol].colour1
              : "Red"
          }
          style={{ outline: "1px solid white" }}
          m="1rem"
          p="0"
          rounded="10px"
          d="flex"
          align="center"
          key={"Red"}
          textWeight="bold"
          textSize="14px"
          spaced={false}
          // isActive={btn.id === activeChoiceButton}
          value={0}
          onClick={() => {
            setGameValue(0);
          }}
          textColor={
            addresses[chainId][gameSymbol].premium === "yes" &&
            addresses[chainId][gameSymbol].colour1 === "white"
              ? "black"
              : "white"
          }
          // isDisabled={!gameReady || gameStarted || gameFinished}
        >
          <Div textAlign="left" p={{ x: "1rem" }} textWeight="bold" w="50%">
            PLACE BET
          </Div>
          <Div p={{ x: "1rem" }} textWeight="bold" w="50%" textAlign="right">
            Win 2x
          </Div>
        </Button>
        <Button
          w={{
            xl: "27rem",
            lg: "27rem",
            md: "30rem",
            sm: "30rem",
            xs: "20rem",
          }}
          h="3rem"
          bg={
            addresses[chainId][gameSymbol].premium === "yes"
              ? addresses[chainId][gameSymbol].colour2
              : "Black"
          }
          style={{ outline: "1px solid white" }}
          m="1rem"
          p="0"
          rounded="10px"
          d="flex"
          align="center"
          key={"Black"}
          textWeight="bold"
          textSize="14px"
          // isActive={btn.id === activeChoiceButton}
          value={1}
          onClick={() => {
            setGameValue(1);
          }}
          textColor={
            addresses[chainId][gameSymbol].premium === "yes" &&
            addresses[chainId][gameSymbol].colour2 === "white"
              ? "black"
              : "white"
          }
          // isDisabled={!gameReady || gameStarted || gameFinished}
        >
          <Div textAlign="left" p={{ x: "1rem" }} textWeight="bold" w="50%">
            PLACE BET
          </Div>
          <Div p={{ x: "1rem" }} textWeight="bold" w="50%" textAlign="right">
            Win 2x
          </Div>
        </Button>
      </Div>

      <CurrentGames gameSymbol={gameSymbol} chainId={chainId} />
      <RecentTransactionsTable gameSymbol={gameSymbol} />
    </Div>
  );
};
