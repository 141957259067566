import React, { useState, useEffect } from "react";
import { Div } from "atomize";
import { addresses } from "../../../contracts/src";

import { WheelImage } from "./RouletteStyles";
import logos from "../../../logos";

import rouletteWheel from "../../../img/roulette.png";

export const RouletteWheel = ({
  spinToColor,
  shouldSpin,
  gameSymbol,
  chainId,
}) => {
  const [isSpinning, setIsSpinning] = useState(false);
  const [isStopping, setIsStopping] = useState(false);
  const [rotateDegrees, setRotateDegrees] = useState(false);
  const [colorToSpin, setColorToSpin] = useState("");

  useEffect(() => {
    if (shouldSpin) {
      setColorToSpin(spinToColor);
      handleSpin();
    }
  }, [shouldSpin]);
  const handleSpin = () => {
    // Calculate the number of degrees to spin based on the spin color
    const degreesPerSection = 360 / 12; // assuming 6 sections in the wheel
    const colors = ["Red", "Black"]; // assuming red and black sections alternate
    const colorIndex = colors.indexOf();
    const sectionsToSpin =
      colorIndex > -1 ? colorIndex : Math.floor(Math.random() * 2);
    const degreesToSpin =
      (sectionsToSpin * 2 + 1) * degreesPerSection +
      Math.floor(Math.random() * degreesPerSection);

    // Start the spin animation
    setIsSpinning(true);
    setRotateDegrees(degreesToSpin);
    setTimeout(() => {
      setIsSpinning(false);
      setRotateDegrees(0);
      setColorToSpin("");
    }, 10000); // set the duration of the spin animation here (in milliseconds)
  };
  return (
    <Div>
      <WheelImage
        src={
          addresses[chainId][gameSymbol].premium === "yes"
            ? logos[gameSymbol]
            : rouletteWheel
        }
        alt="roulette wheel"
        isSpinning={isSpinning}
        colorToSpin={colorToSpin}
      />
    </Div>
  );
};
