import styled, { keyframes, css } from "styled-components";
import { Button } from "atomize";

const rotateRed = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1090deg);
  }
  `;
const rotateBlack = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1070deg);
  }
  `;

export const WheelImage = styled.img`
  animation-name: ${({ isSpinning, colorToSpin }) =>
    isSpinning && colorToSpin === "Red"
      ? css`
          ${rotateRed}
        `
      : isSpinning && colorToSpin === "Black"
      ? css`
          ${rotateBlack}
        `
      : "none"};
  animation-duration: 5s;
  width: 200px;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
`;

export const RouletteStopper = styled("div")`
  position: absolute;
  width: 10px;
  height: 25px;
  background-color: blue;
  top: 5.5rem;
  right: 49.5vw;
`;
export const SpinButton = styled(Button)`
  margin-top: 10px;
`;
