import React, { useState, useEffect } from "react";

import { addresses, abis } from "./contracts/src";

import { Div } from "atomize";
import { TopHeader } from "./components/Header/TopHeader";
import { FlipGame } from "./components/Game/FlipGame";
import { PageCanvas } from "./components/Styles";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { StartPage } from "./components/Game/StartPage";

export const HomePage = () => {
  const [chainId, setChainId] = useState(56);
  const [wrongChain, setWrongChain] = useState(false);
  const [currentToken, setCurrentToken] = useState("bscb");
  const [currentTokenAddress, setCurrentTokenAddress] = useState("");

  useEffect(() => {
    if (currentToken == "bscb") {
      setCurrentTokenAddress(addresses[chainId].bscF.address);
    } else {
      setCurrentTokenAddress(addresses[chainId][currentToken].address);
    }
  }, [currentToken]);

  console.log("You Selected: ", currentToken);

  useEffect(() => {
    setChainId(56);
  }, []);

  return (
    <Div d="flex" flexDir="row">
      <Div bg="#121212" d="flex" flexDir="row" w="100vw">
        <TopHeader />
        <Div d="flex" flexDir="row">
          <PageCanvas id="canvas" />
          <Router>
            <Route exact path="/">
              <StartPage />
            </Route>
            <Route exact path="/Roulette">
              <FlipGame
                chainId={chainId}
                wrongChain={wrongChain}
                currentToken={currentToken}
                onCurrentTokenChange={setCurrentToken}
              />
            </Route>
          </Router>
        </Div>
      </Div>
    </Div>
  );
};
