import bscsImg from "./img/bscs.png";
import chromeImg from "./img/chrome.png";
import tgImg from "./img/tg.png";
import bscbetImg from "./img/newbscb.png";
import poocoinImg from "./img/poocoin.png";
import paypalImg from "./img/paypalwheel.png";
import bscbImg from "./img/bscbetwheel.png";
import wheelImg from "./img/roulette.png";

const logos = {
  bscs: bscsImg,
  chrome: chromeImg,
  tg: tgImg,
  bscbet: bscbetImg,
  poocoin: poocoinImg,
  paypal: paypalImg,
  bscb: bscbImg,
  wheel: wheelImg,
};

export default logos;
